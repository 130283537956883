import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CategoryList from "../../Components/CategoryList";
import SubscriptionFeed from "./Components/SubscriptionFeed";
import BusinessFeed from "./Components/BusinessFeed";

import { FeedContext } from "../../Routes/CustomerRoutes";
import SEO from "../../../Component/Atom/SEO";
import Sidebar from "../../Components/Sidebar";

const Feed = () => {
  const [feedType, setFeedType, sidebar, setSidebar] = useContext(FeedContext);

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, []); 

  return (
    <main className="p-feed">
      <SEO title={feedType ? "Subscriptions" : "Businesses"} />

      {/* Category cards section */}
      {feedType && (
        <div
          className="p-feed__categories"
          style={
            sidebar ? { width: "calc(100vw - 200px)" } : { width: "100vw" }
          }
        >
          <CategoryList />
        </div>
      )}

      {/* Sidebar: only shows on subscription piece */}
      {feedType && (
        <div
          className="l-cSidebar"
          style={!sidebar ? { width: "0px" } : { width: "200px" }}
        >
          <Sidebar />
        </div>
      )}

      {/* Feed Section */}
      {feedType ? <SubscriptionFeed /> : <BusinessFeed />}
    </main>
  );
};

export default Feed;
