import React, { useContext, useEffect, useState } from 'react'
import { ItemDetailContext } from '../../ItemDetail'
import { priceFormat } from '../../../../utilities/global_function'
import { intervalFormat } from '../../../../../Functions/Global/intervalFormat'
import { DiscountConvert } from '../../../../../Functions/Global/transactionalDiscountConvert'
import More from '../../../../../Component/Atom/More'
import { color } from 'framer-motion'
import { checkoutMembership, handleFreeTrialMembershipCheckout, preCheckoutMembership } from '../../../../CustomerStateManagement/Item'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import PopUp from '../../../../../Component/Layer/PopUp'
import { InputBox } from '../../../../../Business_Module/Pages/ItemCreation/Components/InputBox'
import { verifyEmailDuplication } from '../../../../CustomerStateManagement/CustomerState'
import { emailValidation } from '../../../../../Functions/Validation/emailValidation'
import useWindowSize from '../../../../../Functions/Hooks/useWindowSize'
import preCheckoutImg from "../../../../../Assets/precheckout.png"

const ItemContent = () => {
  const [data] = useContext(ItemDetailContext)
  const [accountId, setAccountId] = useState(null)

  const [var1, setVar1] = useState(0)
  const [var2, setVar2] = useState(0)
  const [curSKU, setCurSKU] = useState("")
  const [curSubType, setCurSubType] = useState(null)
  const [qty, setQty] = useState(1)
  const [vdOpen, setVdOpen] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [email, setEmail] = useState("")
  const [emailFill, setEmailFill] = useState(false)

  // Only for old objects
  const [curIndex, setCurIndex] = useState(0)
  const [isOld, setIsOld] = useState(false)
  const isMobile = useWindowSize() 

  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.variation?.length > 0) {
      const variation0Sku = data.variation[0]?.options?.[var1]?.sku || "";
      const variation1Sku = data.variation[1]?.options?.[var2]?.sku || "";
      const newSKU = variation0Sku + variation1Sku;
      setCurSKU(prev => newSKU);
      findSubTypes()
    } else {
      console.log("Data or variation is not fully loaded yet");
      setCurSKU(prev => ""); // Set to an empty string or a default value
    }
  }, [var1, var2, data]);

  useEffect(() => {
    const accountIdLocal = localStorage.getItem("userId") || null
  },[])


  useEffect(() => {
    if (curSKU && data?.subTypes?.length > 0) {
      findSubTypes();
    } else if (data?.subTypes?.length > 0) {
      // Initialize with the first available subType if curSKU is not ready
      setCurSubType(data.subTypes[0]);
    }

    // old object check
    const oldCheck = data.membershipType === "Pick Up" && !data?.subTypes[0]?.minCount
    setIsOld(oldCheck)

  }, [curSKU, data]);

  const findSubTypes = () => {
    const selectedSubType = data?.subTypes?.filter((subType) => {
      const subTypeSkuPart = subType?.sku?.split("-").slice(-1)[0]; // Get the last part of the SKU
      return subTypeSkuPart === curSKU; // Match with curSKU
    });

    if (selectedSubType?.length > 0) {
      setQty(selectedSubType[0]?.minCount || 1)
      setCurSubType(selectedSubType[0]); // Set the first matched subType
    } else {
      setCurSubType(null); // Reset if no match is found
      console.log("No matching subType found");
    }
  }

  const getDiscountPercentage = (qty)=> {
    // Find the maximum applicable discount for the given qty
    if(data.volumetricDiscounts){
      const discount = data?.volumetricDiscounts
        .filter(vol => qty >= vol.quantity) // Filter for all discounts that apply
        .sort((a, b) => b.quantity - a.quantity)[0]; // Get the highest quantity threshold
    
      return discount ? discount.percentage : 0; // Return percentage or 0 if no discount
    }else{
      return 0
    }
  };

  // Checkout
  const handleCheckout = async() => {
    setProcessing(true)
    if(accountId){
      const fieldSet = isOld ? {
        // for old object
        product: data._id,
        quantity: 1,
        customer: accountId,
        price: data?.subTypes[curIndex]?._id,
      } :{
        // for new object
        product: data._id,
        quantity: curSubType?.minCount ? qty / curSubType?.unit: 1,
        customer: accountId,
        price: curSubType?._id,
        }
        try{
          const res = await checkoutMembership(fieldSet)
          window.location.href = res
          setProcessing(false)
        }catch(err){
          console.log(err)
          toast.error("Couldn't Navigate to Stripe Session")
          setProcessing(false)
        }
    }else{
      // direct checkout
      setProcessing(false)
      setEmailFill(true)
    }
  }

  const directCheckoutSubmit = async() => {
    setProcessing(true)
    if(emailValidation(email)){
      try{
        const isEmailExist = await verifyEmailDuplication(email) 
        // true => the email is already in use
        // false => this is unique email and good to use
        if(!isEmailExist){
          const fieldSet = isOld ? {
            // for old object
            product: data._id,
            quantity: 1,
            price: data?.subTypes[curIndex]?._id,
            email:email
          } :{
            // for new object
            product: data._id,
            quantity: curSubType?.minCount ? qty / curSubType?.unit : 1,
            price: curSubType?._id,
            email:email
            }
          const res = await preCheckoutMembership(fieldSet)
          window.location.href = res
          setProcessing(false)
  
        } else{
          toast.error("Sorry this email is already in use.")
          setProcessing(false)
        }
        console.log(isEmailExist)
      }catch(err){
        console.log(err)
        toast.error("preCheckout failed please try again.")
        setProcessing(false)
      }
    }
    setProcessing(false)
  }

  const freeTrialCheckout = async() => {
    setProcessing(true)
    try{
      const accountId = localStorage.getItem("userId")
      const fieldSet = {
        product: data._id,
        quantity: curSubType?.minCount ? curSubType?.minCount / curSubType?.unit: 
        curSubType?.unit,
        customer: accountId,
        price: curSubType?._id,
        }
        const res = await handleFreeTrialMembershipCheckout(fieldSet)
        window.location.href = res
        setProcessing(false)
    }catch(err){
      console.log(err)
        toast.error("Couldn't Navigate to Stripe Session")
        setProcessing(false)
    }

  }



  return (
    <div className='p-itemDetail__content'>
      <Toaster />
      <h4 
        className='p-itemDetail__itemName'
        >{data?.name}</h4>
      <label className='p-itemDetail__businessName' onClick={() => navigate(`/customerStoreFront/${data.business}`)}>{data?.businessName}</label>
      <p className='p-itemDetail__description'>{data?.description}</p>
      {/* Variaion */}
      {
        data?.variation?.length > 0 &&
        <div className='p-itemDetail__variations'>
          {
            data.variation.map((v,vi) => (
              <div className='p-itemDetail__variation' key={vi}>
                <h6>{v.title}</h6>
                  <div className='p-itemDetail__options'>
                    {
                      v.options.map((o,oi) => (
                        <div 
                          className={`p-itemDetail__option ${(vi===0 && oi===var1)|| (vi===1 && oi===var2) ? "p-itemDetail__option--selected" : ""}`}
                          onClick={() => {
                            if (vi === 0) setVar1(oi);
                            if (vi === 1) setVar2(oi);
                          }}
                        >
                          <p>{o.label}</p>
                        </div>
                      ))
                    }
                </div>
              </div>
            ))
          }
        </div>
        }

        {/* Pricing */}
        {
          (curSubType && data.membershipType === "Pick Up" && curSubType?.minCount)  ? (
            // Case1: New object
          <div className='p-itemDetail__pricing'>
            <p>Set Quantity You Would Subscribe</p>
            <p>Unit Price: ${priceFormat(curSubType?.price / curSubType?.unit *(100-getDiscountPercentage(qty))/100)}</p>
            {getDiscountPercentage(qty) > 0 && <label className='m-success'>{getDiscountPercentage(qty)}% discount for higher volume</label>}
            <h5 className='p-itemDetail__price'>
              ${priceFormat(curSubType?.price / curSubType?.unit * qty * (100-getDiscountPercentage(qty))/100)} 
              <label>
                {" "}per {intervalFormat(curSubType?.interval_count,curSubType?.interval)}
              </label>
              {
                (curSubType?.storeUnitPrice && curSubType?.storeUnitPrice * 100 > curSubType?.price / curSubType?.unit) &&
                  <p className='m-error'>was ${priceFormat(curSubType?.storeUnitPrice * 100 * qty)}</p>

              }
            </h5>
            {
              curSubType?.couponInterval > 0 &&
              <label className='m-success p-itemDetail__transactionalDiscount'>Extra {curSubType?.couponPercent}% off {DiscountConvert(curSubType?.couponInterval)}</label>
            }


            <input 
              type='range' 
              className='p-itemDetail__range'
              min={curSubType?.minCount}
              max={curSubType?.maxCount}
              value={qty}
              step={curSubType?.unit}
              onChange={(e) => setQty(e.target.value)}
            />
            <div style={{width:"100%"}} className='l-flex__row--between'>
              <div>
                <p>{curSubType?.minCount} Qty</p>
                <label>Min</label>
              </div>
              <h5>{qty} Qty</h5>
              <div>
                <p>{curSubType?.maxCount} Qty</p>
              <label>Max</label>
              </div>
            </div>
            {
              data?.volumetricDiscounts?.length > 0 &&
              <>
                <div style={{marginLeft:"auto"}}>
                <More 
                  trigger={vdOpen} 
                  setTrigger={setVdOpen} 
                  placeholder="Check More Discount" 
                  cStyle={{color:"#81A666"}}
                />
                </div>
                <div className={`p-itemDetail__vdTable${vdOpen?"--open":""}`}>
                  <table className='p-volume__table'>
                    <tr>
                      <th>Get More than</th>
                      <th>Discount %</th>
                      <th>Unit Price $</th>
                    </tr>
                    {
                      data?.volumetricDiscounts?.map((e,i)=>(
                        <tr>
                          <td>{e.quantity} Qty</td>
                          <td>{e.percentage}%</td>
                          <td>${priceFormat(curSubType.price / curSubType?.unit* (100 - e.percentage)/100)}</td>
                        </tr>
                      ))
                    }
                  </table>
                </div>

              </>
            }

          </div>
          ) : curSubType && data.membershipType === "Pick Up" ? (
            // Case2: Old Pick Up Item
            <div className='p-itemDetail__pricing'>
              <p>Choose your favorite Unit type</p>
              <div className="p-itemDetail__oldSubTypes">
                {
                  data?.subTypes?.map((subType, i) => (
                    <div 
                      className="p-itemDetail__oldSubType--box"
                      style={i===curIndex ? {border:"#97BB7C 1px solid"} : {}}
                      onClick={() => setCurIndex(i)}
                    >
                      <h6>${priceFormat(subType.price)}</h6>
                      <p>{subType.unit} Qty</p>
                      <label>{intervalFormat(subType?.interval_count,subType?.interval)}</label>
                    </div>
                  ))
                }
              </div>
            </div>
          ) : (
            // Case3: Access Item
            <div className='p-itemDetail__pricing' style={{flexDirection:"row",alignItems:"baseline",gap:12}}>
              <h5 style={{margin:"24px 0"}}>$ {priceFormat(curSubType?.price)}</h5>
              <p style={{color:"#979797"}}>{intervalFormat(curSubType?.interval_count,curSubType?.interval)}</p>
            </div>
          ) 
        }

        {/* Checkout */}
        <div className="p-itemDetail__checkOutSection">
          <button 
            className='p-itemDetail__checkOutBtn'
            onClick={() => handleCheckout()}
            disabled={processing}
          >
            {processing? "Navigating" : "Checkout"}
          </button>
          {
            !accountId &&
            <label style={{marginBottom:12}}><b>You haven't signed in yet</b>, but you can still proceed to checkout. After completing your purchase on Stripe, you will receive an email to set up your account. <b>If you already have an existing account</b>, please go back to the <a style={{fontSize:12}} onClick={()=>navigate("/login")}>login page and sign in first.</a></label>
          }
        </div>
        {
          emailFill && 
          <PopUp 
           setOpen={setEmailFill}
           cStyle={isMobile?{}:{width:"60%"}}
          >
            <div className='p-itemDetail__preCheckout'>
              <div>
                <h4 style={{marginBottom:6}}>Subscribe now, signup later!</h4>
                {/* <p>Looks like you don't have an account with Subitt yet <b>don't worry</b>. You can start your membership and setup your account later.<br />Fill in your email you want to use for your account, and we will send you instructions after your purchase.</p> */}
                
              </div> 
              <img 
                src={preCheckoutImg} 
                className='p-itemDetail__preCheckout--img'
                alt="direct checkout instruction"
               />
              <div className='p-itemDetail__preCheckout--form'>
                <label className='c-input__label'>email</label>
                <input 
                type='text'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='c-input'
                placeholder='email'
                />
              </div>
              <button 
                className='c-smallButton' 
                onClick={directCheckoutSubmit}
              >
                {processing?"Navigating":"Submit"}
              </button>
              <label><b>note:</b> if you already have an account, please <a style={{fontSize:12}} onClick={() => navigate("/login")}>sign in</a> first.</label>
            </div>
          </PopUp>
        }
        {
          (curSubType?.isFreeTrialEnabled && (curSubType?.totalFreeTrialRemaining || 999) > 0) &&
          <div className="p-itemDetail__checkOutSection">
            <div className="p-itemDetail__freeTrialInstruction">
              <h5>Try your first subscription free {'\uD83E\uDD73'}</h5>
              <ul>
                <li>This free trial is only applicable for the minimum quantity of the subscription. if after you want a higher quantity to pick up, you can cancel this trial and subscribe again. Post trial, it automatically turns into regular subscription.</li>
              </ul>
              <button 
                className='p-itemDetail__checkOutBtn'
                style={{backgroundColor:"#7f7cbe"}}
                onClick={() => freeTrialCheckout()}
                disabled={processing}
              >
                {processing? "Navigating" : `Start ${curSubType?.freeTrialLength || 30} Days Free Trial`}
              </button>
              <label>Changing account and reuse free trial will violate our <a href='/footer/terms-of-use'>terms of Uses</a></label>
            </div>
        </div>

        }

        {
          data?.menu?.length > 0 &&
          <details className='p-itemDetail__detail'>
            <summary>Menu</summary>
              <div className='p-itemDetail__menu'>
              {
                data?.menu?.map((e,i) => (
                  <>
                  <h6><i>{e.title}</i></h6>
                  <ul>
                    {
                      e?.options?.map((option,oi) => (
                        <li>{option?.optionTitle} <br /><label>{option?.description || ""}</label></li>
                      ))
                    }
                  </ul>
                  </>
                ))
              }
              </div>
          </details>
        }
        {
          data?.specialReward &&
          <details className='p-itemDetail__detail'>
            <summary>Special Reward</summary>
            <div>
              <p className='p-itemDetail__detail--string'>{data?.specialReward}</p>
            </div>
          </details>
        }
        {
          data?.howToPurchase &&
          <details className='p-itemDetail__detail'>
            <summary>How to Purchase</summary>
            <div>
            <p className='p-itemDetail__detail--string'>{data?.howToPurchase}</p>
            </div>
          </details>
        }
        {
          data?.note &&
          <details className='p-itemDetail__detail'>
            <summary>Note</summary>
            <div>
              <p className='p-itemDetail__detail--string'>{data?.note}</p>
            </div>
          </details>
        }
        {
          data?.termsAndConditions &&
          <details className='p-itemDetail__detail'>
            <summary>Terms and Conditions</summary>
            <div>
              <p className='p-itemDetail__detail--string'>{data?.termsAndConditions}</p>
            </div>
          </details>
        }


    </div>
  )
}

export default ItemContent