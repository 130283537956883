import React from 'react'
import InformationIcon from '../../../Utils/InformationIcon'

export const InputBox = ({
    title, 
    instruction, 
    tipId="", 
    tipContent="", 
    inputType,
    fieldName,
    value,
    onChange,
    placeholder="",
    required=false,
    maxLength=9999,
    sign="", // dollar or percent
    min=false | undefined,
    max=false | undefined,
    btnFunction=() => {},
    inputStyle={},
    step="1",
    error
  }) => {
  return (
    <div className='c-objInput__container'>
      <InputTitle 
      title={title}
      tipId={tipId}
      tipContent={tipContent}
      instruction={instruction}
      error={error}
       />
      <InputSection
            inputType={inputType}
            fieldName={fieldName}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            maxLength={maxLength}
            sign={sign}
            min={min}
            max={max}
            btnFunction={btnFunction}
            inputStyle={inputStyle}
            step={step}
      />
    </div>
  )
}

export const InputTitle = ({title,tipId="",tipContent="",instruction,error}) => {

  return (
    <>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
    <h5 id={title.replaceAll(" ","_")}>{title}</h5>
    {
      tipId &&
      <InformationIcon 
        size={14} 
        style={{marginLeft:8}}
        tipId={tipId}
        tipContent={tipContent}
      />
    }
  </div>
  
  <p className='c-objInput__instruction'>
  {instruction.split("\n").map((line, index) => (
    <span key={index} style={{ display: "block", margin: "0", padding: "0", fontSize:16 }}>
    {line}
  </span>
        ))}
  </p>
  {
    error.isError &&
    <p className='m-error'>{error.errorMessage}</p>
  }
    </>
  );
}

export const InputSection = ({
  inputType,
  fieldName,
  value,
  onChange,
  placeholder="",
  required=false,
  maxLength=9999,
  sign="", // dollar or percent
  min=undefined,
  max=undefined,
  btnFunction=() => {},
  inputStyle={},
  step="1"
}) => {
  return (
    <div className='c-objInput__inputBox'>
    {
      inputType === "textBox" ? (
        <>
          <textarea
            value={value}
            maxLength={maxLength}
            className='c-objInput__textarea'
            placeholder={placeholder}
            onChange={(e) => onChange(e,fieldName)}
            required={required}
            rows={5}
            style={inputStyle}
          />
          {maxLength !== 9999 && <label className='c-objInput__textLength'>{value.length} / {maxLength}</label>}
          
        </>
      ) : inputType === "option" ? (
        <input />
      ) : inputType === "button" ? (
        <>
          <button 
            onClick={btnFunction}
            className='c-objInput__btn'
            style={inputStyle}
          >{placeholder}
          </button>
        </>
      ) :(
        <>
          {/* dollar icon */}
          {sign === "dollar" && 
          <div className='c-objInput__sign'>
            <p>$</p>
          </div>}
          <input 
          type={inputType}
          placeholder={placeholder}
          value={value || null}
          onChange={(e) => onChange(e,fieldName)}
          required={required}
          {...((sign === "percent" || sign === "dollar") ? 0 : min !== undefined ? { min } : {})} 
          {...(sign === "percent" ? 100 : max !== undefined ? { max } : {})} 
          onWheel={(e) => e.target.blur()}
          step={sign === "dollar" ? "0.01" : sign === "percent" ? "1" : step}
          style={sign==="percent" ? {width:80,inputStyle} : sign==="dollar" ? {width:100,inputStyle} : inputStyle}
          />
          {/* length count */}
          {maxLength !== 9999 && <label className='c-objInput__textLength'>{value.length} / {maxLength}</label>}

          {/* percent icon */}
          {sign === "percent" && 
          <div className='c-objInput__sign'>
            <p>%</p>
          </div>}

        </>
      )
    }
    </div>
  );
}