import React from 'react'
import 'ldrs/ring'
import useWindowSize from '../../Functions/Hooks/useWindowSize';
import { TailChase } from 'ldrs/react'
import 'ldrs/react/TailChase.css'


/**
 * Coder: Aoi Kuriki,
 * Design Plan : Maya Patten
 * Last Update : 3/26/2025
 * Use cases:
 * Contents => Skelton Design
 * Page => Spinner
 * Submission => Progress Circle
 * Number Loading => Percentage Indicator
 * (Longer Loading => Hour Glass animation : needs definition of long load)
 * (Navigation Wait time => Loading Text)
 * (Long Submission => Combine Animation
 * For more detail: Huly @DEV-37 Loading Indicator
 **/

interface LoadingIndicatorProps {
  type: "spinner" | "progress-circle" | "percentage" | "hourglass" | "loading-text" | "long-submission";
  isCustomer: boolean
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ type, isCustomer=true }) => {
  const isMobile = useWindowSize();
  const customerColor = "#3C6124";
  const businessColor = "#7f7cbe";
  const webSize = 80;
  const mobileSize = 40;
  const animationSpeed = 1.75
  
  switch (type) {
    case "spinner":
      return <TailChase
      size={isMobile?mobileSize:webSize}
      speed={animationSpeed}
      color={isCustomer?customerColor:businessColor}
    />
    case "progress-circle":
      return <div className="progress-circle"></div>;
    case "percentage":
      return <div className="percentage-loader">50%</div>;
    case "hourglass":
      return <div className="hourglass"></div>;
    case "loading-text":
      return <div>Loading...</div>;
    case "long-submission":
      return (
        <div>
          <div className="spinner"></div>
          <div className="progress-circle"></div>
        </div>
      );
    default:
      return null;
  }
};

export default LoadingIndicator;