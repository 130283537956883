/*******************************************************************************************************
 * Function/File Purpose: This component is for displaying items which are provided by a specific company as a grid display
 * Author: Aoi Kuriki
 * Date: 6/2/2023
 * 
 * **********************************
 * Contributor:
 * Date:
 * What was Done:
 * *******************************
 * Contributor:
 * Date:
 * What was done:
 * ********************************
 */
import React, { Suspense, useEffect, useState } from 'react'
import Subtitle from '../../../../GlobalComponents/Modules/Atom/Subtitle'
import ItemCardSkelton1 from '../../../utilities/ItemCardSkelton1'
import { getAllItemsByBusiness } from '../../../CustomerStateManagement/Item'
const ItemCard = React.lazy(() => import("../../Feed/Components/SubscriptionComponents/SubscriptionCard"))


const RelatedItems = ({businessId}) => {

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const loadSkelton = () => {
    const componentArray = [];
  
    for (let i = 0; i < 8; i++) {
      componentArray.push(<ItemCardSkelton1 key={i} />);
    }
  
    return componentArray;
  }



  useEffect(() => {
    const fetchRelatedProducts = async () => {
      const result = await getAllItemsByBusiness(businessId);
      setItems(result)
    };
    fetchRelatedProducts();
    setLoading(false)
  },[])

  if(loading){return (
    <div className="skeltonAlign">
      {loadSkelton()}
    </div>
    )}

  return (
    <section className='p-relatedItems__container'>
    {
      items?.length === 0? (
       <></>
      ) : (
      <>
        <h4 className='p-relatedItems__title'>{`All Items Provided by ${items[0]?.businessName}`}</h4> 
        <div className="l-cardLayout">
          {items.length > 0 && items?.map((item,index)=>(
            <Suspense key={index} fallback={<ItemCardSkelton1 />}>
               <ItemCard product={item} />
            </Suspense>    
          ))}
          
        </div>
      </>
      )
    }
    </section>

  )
}

export default RelatedItems