import React from "react";
import { useNavigate } from "react-router-dom";

const ItemCategory = ({ categories = [] }) => {
  const navigate = useNavigate();
  return (
    <div className="p-itemDetail__category">
      {typeof categories !== "object" ? (
        <div
          className="p-itemDetail__category--box"
          onClick={() => navigate(`/browse?category=${categories}`)}
        >
          <label>{categories}</label>
        </div>
      ) : (
        categories?.slice(0,3)?.map((e, i) => {
          const [parent, sub] = e.split(">");
          return (
            <div
              key={i}
              className="p-itemDetail__category--box"
              onClick={() => navigate(`/browse?category=${sub}`)}
            >
              <label>{parent || "Unknown"}</label>
              <p>{sub || "Unknown"}</p>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ItemCategory;
