import React from "react";
import CustomerNavBar from "./CustomerNavBar";

const CustomerHeader = () => {
  return (
    // Isaac code needs to refactor later
    <header className="l-cNav">
      <CustomerNavBar />
    </header>
  );
};

export default CustomerHeader;
